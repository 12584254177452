import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  Flex,
  Text,
  Heading,
  Box,
  Button,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"
import HeroLeft from "../../components/hero-left"
import HeroRight from "../../components/hero-right"

const styles = {
  root: {
    width: "100%",
    height: "auto",
    px: "24px",
  },

  "heading-box": {
    margin: "0 auto",
    mb: "32px",

    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    mt: { base: "24px", md: "24px", lg: "32px" },
  },

  hero: {
    "inner-box": {
      width: { base: "100%", md: "100%", lg: "1112px" },
      justifyContent: {
        base: "flex-start",
        md: "flex-start",
        lg: "space-between",
      },
      alignItems: { base: "center", md: "center", lg: "flex-start" },
      flexDirection: { base: "column", md: "column", lg: "row" },
    },
  },

  "title-box": {
    width: "100%",
    mb: { base: "24px", md: "24px", lg: "32px" },
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
  },
  title: {
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "32px" },
    fontWeight: { base: "500", md: "500", lg: "700" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  "content-box": {
    mt: { base: "47px", md: "47px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "429px" },
    flexDirection: "column",
    ml: { base: "0px", md: "0px", lg: "139px" },
  },

  "content-box-border": {
    flexDirection: "column",
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
    border: { base: "1px solid #DCE0E5", md: "1px solid #DCE0E5", lg: "none" },
    borderRadius: { base: "8px", md: "8px", lg: "none" },
    padding: { base: "35px", md: "35px", lg: "0px" },
    mt: { base: "24px", md: "24px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "543px" },
  },

  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  "button-box": {
    flexDirection: "row",
    justifyContent: "center",
    margin: "0 auto",
  },
  button: {
    mt: "32px",
    bg: "#129459",
    padding: "16px 32px",
    height: "56px",
    width: "201px",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  whitespace: {
    pb: "24px",
  },
}
const navigateToPACUAndORThroughput = () =>
  navigate("/learn-more-about-PACU-and-OR-throughput")

const SectionOR = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>
          Your OR is Likely your Greatest Source of Revenue... and Cost
        </Heading>
      </Flex>
      <HeroLeft
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/throughput/throughput-or-a.png"
            alt="Illustration of a doctor examining a patient on a table against a blue background"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box"]}>
          <Text sx={styles.text}>
            Backups in the PACU often result in delays and patient holds in the
            OR.
            <Text sx={styles.whitespace}></Text>
            When your OR staff is forced to perform post-op patient monitoring,
            this is usually the costliest treatment provided in your hospital.
            It may also be some of the most avoidable.
            <Text sx={styles.whitespace}></Text>
            With the CareMeasurement Throughput application you can greatly
            reduce and even eliminate costly OR holds by accurately predicting
            when floor beds will be needed for surgical admits to avoid holding
            patients in the PACU because floor beds aren't available.
          </Text>
        </Flex>
      </HeroLeft>
      <HeroRight
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/throughput/throughput-or-b.png"
            alt="Illustration of three people engaging with each other around a computer in front of a background of lightbulbs and gears"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box-border"]}>
          <Flex sx={styles["title-box"]}>
            <Heading sx={styles.title}>
              What are your true costs of PACU backups?
            </Heading>
          </Flex>

          <UnorderedList>
            <ListItem>Cascading delays and patient holds in the OR</ListItem>
            <ListItem>
              Procedure cancellations and patient rescheduling
            </ListItem>
            <ListItem>Reduced staff morale and patient satisfaction</ListItem>
            <ListItem> Increased cost of overtime pay</ListItem>
            <ListItem>Reduced day-of-surgery PT</ListItem>
            <ListItem>Reduced utilization of floor bed capacity</ListItem>
          </UnorderedList>
          <Text sx={styles.whitespace}></Text>
          <Text sx={styles.text}>
            The unique CM Throughput application can help you obviate these
            challenges as you accurately predict and manage patient flow
            throughout the perioperative process.
          </Text>
        </Flex>
      </HeroRight>

      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          margin: "0 auto",
          pb: "32px",
        }}
      >
        <Button
          title="Click here to take a closer look at our CM Throughput application in action."
          sx={styles.button}
          onClick={navigateToPACUAndORThroughput}
        >
          Take a Closer Look
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionOR
