import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"

const styles = {
  root: {
    width: "100%",
    height: "auto",
    px: "24px",
  },
  "heading-box": {
    margin: "0 auto",
    mt: { base: "24px", md: "24px", lg: "32px" },
    mb: "32px",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
  },

  "inner-box": {
    margin: "0 auto",
    mt: "32px",
    maxWidth: "1112px",
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    justifyContent: { base: "center", md: "center", lg: "space-between" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
  },
  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  hero: {
    maxWidth: { base: "100%", md: "100%", lg: "543px" },
    height: { base: "auto", md: "auto", lg: "344px" },
  },
  button: {
    display: { base: "none", md: "none", lg: "block" },
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: "56px",
    width: "201px",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  whitespace: {
    pb: "24px",
  },
}

const navigateToPACUAndORThroughput = () =>
  navigate("/learn-more-about-PACU-and-OR-throughput")

const SectionAccurateForecasting = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>
          Improve Floor Bed Capacity Utilization with Accurate Forecasting
        </Heading>
      </Flex>

      <Flex sx={styles["inner-box"]}>
        <Flex
          sx={{
            flexDirection: "column",
            maxWidth: { base: "100%", md: "429px", lg: "429px" },
            mt: { base: "24px", md: "0px", lg: "0px" },
          }}
        >
          <Text sx={styles.text}>
            Without accurate forecasting of patient flow from the PACU, it is
            very difficult to ensure a bed is available for each patient and
            move them out of the PACU to ensure the next patient can enter the
            PACU without delays.
          </Text>
          <Text sx={styles.text} mt="24px">
            A lack of capacity results in poor patient satisfaction and excess
            capacity means missed revenue opportunities coupled with unnecessary
            expenses.
          </Text>
          <Text sx={styles.text} mt="24px">
            With the CareMeasurement Throughput application you can greatly
            improve the forecast flow of patients out of the PACU, with granular
            visibility into the expected time of arrival and type of floor bed
            needed for each surgical admit patient.
          </Text>
        </Flex>
        <Box sx={styles.hero}>
          <StaticImage
            alt="Image of doctors and healthcare providers in white lab coats and in scrubs wearing masks."
            src="../../images/throughput/throughput-forecasting-a.png"
            placeholder="blurred"
          />
        </Box>
      </Flex>
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "147px",
          margin: "0 auto",
          pb: "32px",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigateToPACUAndORThroughput}
        >
          Learn More
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionAccurateForecasting
