import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import merge from "lodash/merge"

const defaultStyles = {
  root: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    mt: { base: "24px", md: "24", lg: "32px" },
  },

  "inner-box": {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: { base: "column", md: "column", lg: "row" },
  },
  hero: {
    display: { base: "none", md: "none", lg: "block" },
  },
}
const HeroRight = ({ Image, children, styles }) => {
  const _styles = merge({}, defaultStyles, styles)
  return (
    <Flex sx={_styles.root}>
      <Flex sx={_styles["inner-box"]}>
        {children}
        <Box sx={_styles.hero}>
          <Image />
        </Box>
      </Flex>
    </Flex>
  )
}
export default HeroRight
