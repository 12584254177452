import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  Flex,
  Text,
  Heading,
  Box,
  Button,
  Link,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

const styles = {
  root: {
    height: { base: "auto", md: "auto", lg: "600px" },
    width: "100%",
    px: "24px",
    overflowX: "hidden",
    variant: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  "inner-box": {
    margin: "0 auto",
    mt: { base: "32px", md: "32px", lg: "80px" },
    width: "100%",
    height: { base: "100%", md: "100%", lg: "456px" },
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: { base: "flex-start", md: "flex-start", lg: "center" },
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "700px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    mr: { base: "0px", md: "0px", lg: "28px" },
  },
  "heading-box": {
    width: "100%",
    mb: { base: "24px", md: "24px", lg: "32px" },
    mt: { base: "24px", md: "24px", lg: "0px" },
  },
  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  text: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  hero: {
    height: { base: "auto", md: "auto", lg: "495px" },
    width: { base: "100%", md: "100%", lg: "544px" },
  },
  "icon-box": {
    display: { base: "flex", md: "flex", lg: "none" },
    my: "17px",
    width: "100%",
    justifyContent: "center",
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
  },

  whitespace: {
    pb: "24px",
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: "56px",
    width: "201px",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigateToPACUAndORThroughput = () =>
  navigate("/learn-more-about-PACU-and-OR-throughput")

const SectionHero = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["icon-box"]}>
        <ChevronDownIcon sx={styles.icon} />
      </Flex>
      <Flex sx={styles["left-box"]}>
        <Box sx={styles["heading-box"]}>
          <Heading sx={styles.heading}>PACU and OR</Heading>
          <Heading sx={styles.heading}>Throughput Optimization</Heading>
        </Box>
        <UnorderedList>
          <ListItem>
            Is your PACU backed up due to a lack of available beds on the floor?
          </ListItem>
          <ListItem>
            Is your OR schedule frequently delayed due to bottlenecks in the
            PACU?
          </ListItem>
          <ListItem>
            Are these OR delays frustrating your clinical teams and driving up
            your staffing costs?
          </ListItem>
        </UnorderedList>

        <Text sx={styles.text}>
          With the CareMeasurement Throughput application you can eliminate
          bottlenecks and delays by accurately predicting patient flow, into and
          out of the PACU, and proactively making the necessary staff and
          scheduling adjustments.
          <Text sx={styles.whitespace} />
          Ready to transition from reactive to proactive planning and
          scheduling?
        </Text>
        <Button sx={styles.button} onClick={navigateToPACUAndORThroughput}>
          Take a Closer Look
        </Button>
      </Flex>
      <Link onClick={navigateToPACUAndORThroughput}>
        <Box sx={styles.hero}>
          <StaticImage
            src="../../images/throughput/throughput-hero.png"
            alt="Click here to request a demonstration of our PACU and OR Throughput application."
            placeholder="blurred"
          />
        </Box>
      </Link>
    </Flex>
  </Box>
)

export default SectionHero
