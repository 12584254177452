import React from "react"
import Layout from "../components/layout"
import SectionHero from "../components/throughput/section-hero"
import SectionOR from "../components/throughput/section-or"
import SectionTopChallenges from "../components/throughput/section-challenges"
import SectionAccurateForecasting from "../components/throughput/section-forecasting"
import SectionCloserLook from "../components/throughput/section-closer-look"

class PacuAndORThroughput extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location}
        title={"PACU and OR Throughput Optimization | Avant-garde Health"}
      >
        <SectionHero />
        <SectionOR />
        <SectionTopChallenges />
        <SectionAccurateForecasting />
        <SectionCloserLook />
      </Layout>
    )
  }
}

export default PacuAndORThroughput
