import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import merge from "lodash/merge"

const defaultStyles = {
  root: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    mb: { base: "24px", md: "24px", lg: "44px" },
  },

  "inner-box": {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: { base: "column", md: "column", lg: "row" },
  },
}
const HeroLeft = ({ Image, children, styles }) => {
  const _styles = merge({}, defaultStyles, styles)
  return (
    <Flex sx={_styles.root}>
      <Flex sx={_styles["inner-box"]}>
        <Box>
          <Image />
        </Box>
        {children}
      </Flex>
    </Flex>
  )
}
export default HeroLeft
